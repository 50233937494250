import React from "react";

import { Article, DEFAULT_LENGTH, INIT_COMMENT_LENGTH } from "./types";
import ServiceNewspicksComments from "./ServiceNewspicksComments";
import ExternalArticleSummary from "./ExternalArticleSummary";
import ArticlesListLoading from "./ArticlesListLoading";
import AlertError from "components/Alerts/AlertError";
import AlertInfo from "components/Alerts/AlertInfo";
import CardServiceChart from "components/Charts/CardServiceChart";
import { useSWRFetch } from "libs/api";

type Props = {
  category?: string;
  page?: number;
  length?: number;
  query?: string;
  className?: string;
};
export default function ServiceNewspicksCategory({
  category = "",
  page = 0,
  length = DEFAULT_LENGTH,
  query = "",
  className,
}: Props) {
  if (page < 0) page = 0;
  const start = page * length;
  const q =
    query && query.length > 1 ? "&search[value]=" + encodeURI(query) : "";
  const url = `${process.env.REACT_APP_API_PATH_NEWSPICKS}?c=${category}&start=${start}&length=${length}${q}`;
  const { data: news, isLoading, error, noData } = useSWRFetch(url);

  if (error)
    return (
      <div className="flex flex-wrap">
        <div className="w-full p-3">
          <AlertError message={`Could not load NewsPicks ${category} news`} />
        </div>
      </div>
    );
  if (isLoading) return <ArticlesListLoading />;

  if (noData)
    return (
      <div className="flex flex-wrap">
        <div className="w-full p-3">
          <AlertInfo message={`There is no NewsPicks ${category} news`} />
        </div>
      </div>
    );

  return (
    <div className={className}>
      <div className="w-full">
        {news.map((article: Article, i: number) => (
          <div key={i} className="w-screen md:w-full">
            <ExternalArticleSummary article={article} />
            <ServiceNewspicksComments
              link={article.link}
              show={INIT_COMMENT_LENGTH}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
